import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: "gom.digital",
  databaseURL: "https://go-marketing-apps-default-rtdb.firebaseio.com",
  projectId: "go-marketing-apps",
  storageBucket: "go-marketing-apps.appspot.com",
  messagingSenderId: "113419740745",
  appId: "1:113419740745:web:7744846d8f10e0365e4198",
  measurementId: "G-862055GFM8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const analytics = getAnalytics(app);
var database = getDatabase(app);

const firebaseInit = { analytics, storage, database };

export default firebaseInit;
