import React from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Dropdown, Radio } from "flowbite-react";

import { LanguagePanel } from "../../../layouts/imgGenerator/language-panel";
import {
  DetectLanguage,
  TranslateInput,
} from "../../../services/firebaseActions/language";
import { LanguageFont } from "../../../utilities/utilityService";
import {
  images,
  imageSizes,
  presenters,
} from "../../../helpers/imgGenerator/webinarObjs";
import { ImageSizeSelector } from "../../../layouts/imageSizeSelector";

const initialState = {
  id: 1,
  name: "Default",
  image: "/go-default-webinar.jpg",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Webinars(props) {
  const [selected, setSelected] = useState(images[0]);
  const [selectedPresenter, setSelectedPresenter] = useState(presenters[0]);
  const topText = props.data.topText;
  const subText = props.data.subText;
  const date = props.data.date;
  const time = props.data.time;
  const presenterName = props.data.presenterName;
  const presenterCreds = props.data.presenterCreds;
  const webinarOverlayType = props.data.webinaroverlay;

  const [checked, setChecked] = useState(false);

  //translator
  const translateTop = TranslateInput(topText, "en", props.data.lang);
  const translateBottom = TranslateInput(subText, "en", props.data.lang);
  //detector
  const detectLang = DetectLanguage(topText);
  //font
  const { fontStyle, btmFontStyle } = LanguageFont(detectLang);

  return (
    <div className="webinar">
      <div className="grid grid-cols-12 gap-8 sm:px-4">
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <p className="mb-6">
            Create and customise your image by using the fields below webinars.
          </p>

          <form>
            <div className="st-3">
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Settings
              </h3>

              <div className="image-details">
                <div className="flex justify-left">
                  <ImageSizeSelector
                    handleChange={props.handleChange}
                    sizeObj={imageSizes}
                    name="webinaroverlay"
                  />
                </div>
                <div className="input-group my-8">
                  <div className="form-group">
                    <Listbox
                      value={selected}
                      onChange={(e) => {
                        setSelected(e);
                        props.handleSelectAlt(e);
                      }}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="font-body text-sm font-normal text-sub text-muted mb-3">
                            Select Key Visual
                          </Listbox.Label>
                          <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-pointer border border-border bg-white py-2 pl-3 pr-10 text-left focus:border-gogreen focus:outline-none focus:ring-1 focus:ring-gogreen sm:text-sm">
                              <span className="flex items-center cursor-pointer">
                                <img
                                  src={selected.image}
                                  alt=""
                                  className="h-6 w-6 flex-shrink-0"
                                />
                                <span className="ml-3 block truncate text-sm">
                                  {selected.name}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="grid grid-cols-4 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {[...images].reverse().map((imageitem) => (
                                  <Listbox.Option
                                    key={imageitem.id}
                                    className={({ active }) =>
                                      classNames(
                                        active ? "text-white" : "text-sub",
                                        "relative cursor-pointer select-none py-2 pl-3 pr-9 w-full"
                                      )
                                    }
                                    value={imageitem}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <div className="block">
                                          <img
                                            src={imageitem.image}
                                            alt=""
                                            className="h-20 w-auto flex-shrink-0"
                                          />
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "text-sub text-xs mt-2 block truncate"
                                            )}
                                          >
                                            {imageitem.name}
                                          </span>
                                        </div>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-gogreen"
                                                : "text-gogreen",
                                              "absolute inset-y-0 top-3 left-4 flex items-top pr-4 z-50"
                                            )}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              className="w-6 h-6"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                              />
                                            </svg>
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>
                <p className="font-body text-sm font-normal text-sub text-muted mb-3">
                  Or Upload Key Visual{" "}
                  <span className="italic text-xs">
                    (1200px x 628px or 1080px x 1080px for best results.)
                  </span>
                </p>
                <div className="input-group">
                  <div className="custom-file relative">
                    <div className="max-w-xl">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col justify-center items-center w-full h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="mb-3 w-10 h-10 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>

                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF (MAX. 400x400px)
                          </p>
                        </div>
                        <input
                          onChange={props.handleChange}
                          type="file"
                          className="custom-file-input block w-full font-body text-sm text-sub cursor-pointer absolute h-full color-transparent -indent-56 focus:border-gogreen focus:ring-gogreen"
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          accept="image/x-png,image/gif,image/jpeg"
                          name="webinarImg"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="st-2">
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Details
              </h3>

              <div className="image-settings">
                <h3 className="font-display text-base font-bold text-sub mb-1">
                  Series or Session Information
                </h3>
                <p className="mb-6 text-sm">
                  Here you can display the topic and name of the educational
                  series
                </p>
                <div className="input-group">
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Webinar Series Type (Optional)
                  </label>
                  <input
                    className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    type="text"
                    name="subText"
                    placeholder="SUB LABEL OPTION"
                    value={props.data.subText}
                    onChange={props.handleChange}
                  />
                  <div className="colorPicker mb-6">
                    <Dropdown label="Select Colour">
                      <fieldset
                        className="flex justify-start align-middle"
                        id="radio"
                        name="subTextColor"
                        onChange={props.handleChange}
                      >
                        <div className="grid grid-cols-5 gap-1">
                          <div>
                            <Radio
                              id="orange"
                              name="subTextColor"
                              value="customRed"
                              defaultChecked={true}
                            />
                          </div>
                          <div>
                            <Radio
                              id="green"
                              name="subTextColor"
                              value="gogreen"
                            />
                          </div>
                          <div>
                            <Radio
                              id="blue"
                              name="subTextColor"
                              value="checkboxBlue"
                            />
                          </div>
                          <div>
                            <Radio
                              id="red"
                              name="subTextColor"
                              value="checkboxRed"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </Dropdown>
                  </div>
                </div>
                <div className="input-group">
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Title
                  </label>
                  <input
                    className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    type="text"
                    name="topText"
                    placeholder="Webinar theme or educational topic"
                    value={props.data.topText}
                    onChange={props.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="st-2">
              <div className="image-details mt-10">
                <h3 className="font-display text-lg font-bold text-sub mb-6">
                  Session Details
                </h3>

                <div className="input-group">
                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label className="font-body text-sm font-normal text-sub w-full">
                        Date
                      </label>
                      <input
                        className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                        type="text"
                        name="date"
                        placeholder="Enter the session date"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div>
                      <label className="font-body text-sm font-normal text-sub w-full">
                        Time
                      </label>
                      <input
                        className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                        type="text"
                        name="time"
                        placeholder="Enter the session time"
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </div>
                {/* checkbox  */}
                <div className="mt-10">
                  <div className="input-group inline-block w-full">
                    <label
                      htmlFor="checked-checkbox"
                      className="inline-block float-left mb-7 font-body text-sm font-normal text-sub italic"
                    >
                      Would you like Presenter Information to be on the image?
                    </label>
                    <input
                      id="checked-checkbox"
                      type="checkbox"
                      className="cursor-pointer inline-block float-right w-4 h-4 mt-1 text-gogreen bg-white-100 border-border focus:ring-gogreen dark:focus:ring-gogreen dark:ring-offset-gogreen focus:ring-2 dark:bg-gogreen dark:border-gogreen"
                      //checked={checked}
                      onChange={() => {
                        setChecked(!checked);
                      }}
                    />
                  </div>
                  <div
                    className={`input-group ${
                      !checked && "w-full space-y-2 z-5 blur"
                    }`}
                  >
                    <h3 className="font-display text-lg font-bold text-sub mb-5">
                      Presenter Details
                    </h3>
                    <p className="mb-6 text-sm">Presenter Profile Picture</p>
                    <div className="form-group">
                      <Listbox
                        value={selectedPresenter}
                        disabled={checked ? false : true}
                        onChange={(e) => {
                          setSelectedPresenter(e);
                          props.handleSelectPresenter(e);
                        }}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="font-body text-sm font-normal text-sub text-muted mb-3">
                              Select Presenter
                            </Listbox.Label>
                            <div className="relative mt-1 mb-6">
                              <Listbox.Button className="relative w-full cursor-pointer border border-border bg-white py-2 pl-3 pr-10 text-left focus:border-gogreen focus:outline-none focus:ring-1 focus:ring-gogreen sm:text-sm">
                                <span className="flex items-center cursor-pointer">
                                  <img
                                    src={selectedPresenter.image}
                                    alt=""
                                    className="h-6 w-6 flex-shrink-0"
                                  />
                                  <span className="ml-3 block truncate text-sm">
                                    {selectedPresenter.name}
                                  </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="grid grid-cols-4 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {presenters.map((imageitem) => (
                                    <Listbox.Option
                                      key={imageitem.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? "text-white" : "text-sub",
                                          "relative cursor-pointer select-none py-2 pl-3 pr-9 w-full"
                                        )
                                      }
                                      value={imageitem}
                                    >
                                      {({ selectedPresenter, active }) => (
                                        <>
                                          <div className="block">
                                            <img
                                              src={imageitem.image}
                                              alt=""
                                              className="h-20 w-auto flex-shrink-0"
                                            />
                                            <span
                                              className={classNames(
                                                selectedPresenter
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "text-sub text-xs mt-2 block truncate"
                                              )}
                                            >
                                              {imageitem.name}
                                            </span>
                                          </div>

                                          {selectedPresenter ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-gogreen"
                                                  : "text-gogreen",
                                                "absolute inset-y-0 top-3 left-4 flex items-top pr-4 z-50"
                                              )}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                              </svg>
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>

                    <div className="flex justify-center items-center w-full relative">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col justify-center items-center w-full h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="mb-3 w-10 h-10 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>

                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF (MAX. 400x400px)
                          </p>
                        </div>
                        <input
                          onChange={props.handleChange}
                          disabled={checked ? false : true}
                          type="file"
                          className="custom-file-input block w-full font-body text-sm text-sub cursor-pointer absolute h-full color-transparent -indent-56 focus:border-gogreen focus:ring-gogreen"
                          id="dropzone-file"
                          aria-describedby="inputGroupFileAddon04"
                          accept="image/x-png,image/gif,image/jpeg"
                          name="secondaryImg"
                        />
                      </label>
                    </div>

                    <div className="input-group mt-5">
                      <label className="font-body text-sm font-normal text-sub w-full ">
                        Presenter Name
                      </label>
                      <input
                        className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                        type="text"
                        name="presenterName"
                        placeholder="Enter presenters name"
                        value={props.data.presenterName}
                        onChange={props.handleChange}
                        disabled={checked ? false : true}
                      />
                      <label className="font-body text-sm font-normal text-sub w-full">
                        Presenter Credentials
                      </label>
                      <input
                        className="form-control text-sm w-full border-border mt-1.5 mb-4 focus:border-gogreen focus:ring-gogreen"
                        type="text"
                        name="presenterCreds"
                        placeholder="Enter presenters title or acheivements"
                        value={props.data.presenterCreds}
                        onChange={props.handleChange}
                        disabled={checked ? false : true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LanguagePanel
              topText={topText}
              translateTop={translateTop}
              bottomText={subText}
              translateBottom={translateBottom}
              handleLanguage={props.handleChange}
              topTextPlaceHolder="Webinar theme or educational topic"
              btmTextPlaceHolder="SUB LABEL OPTION"
            />
          </form>
        </div>
        <div
          className={`col-span-7 sm:pl-0 ${
            webinarOverlayType === "overlay1" ? "lg:pl-7" : "lg:pl-11"
          }`}
        >
          {/* Preview component */}
          <div
            id="gen-img-preview"
            className={`DisplayImg ${props.data.webinaroverlay} DisplayImgPreview hidden sm:hidden lg:block`}
          >
            {webinarOverlayType === "overlay3" ? (
              <>
                <div
                  id="top"
                  className="flex relative justify-center items-end"
                >
                  <div className="max-w-[537.4px] max-h-[284.89px] mb-[300px]">
                    <h2 className={`text-white ${fontStyle}`}>
                      {topText
                        ? topText
                        : "Title of the webinar or seminar goes here"}
                    </h2>
                  </div>
                  <div className="text-area flex items-center">
                    <div>
                      <p
                        className={`text-[23px] leading-[27.84px] ${btmFontStyle}`}
                      >
                        {props.data.presenterName
                          ? props.data.presenterName
                          : " FIRSTNAME LASTNAME"}
                      </p>
                      <p
                        className={`text-[18px] leading-[21.78px] ${btmFontStyle}`}
                      >
                        {props.data.presenterCreds
                          ? props.data.presenterCreds
                          : " TITLE | POSITION | CREDITIALS"}
                      </p>
                    </div>
                  </div>
                  <div className="img-area">
                    <img
                      src={props.data.webinarImg}
                      className="img-fluid"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  id="bottom"
                  className="flex relative justify-center items-center"
                >
                  <div className="text-area flex justify-start items-center">
                    <p className={btmFontStyle}>
                      {date ? (
                        <>{date + ", " + time}</>
                      ) : (
                        "Month DD, HH:MM TIMEZONE"
                      )}
                    </p>
                  </div>
                  <div className="img-area">
                    <img
                      src={props.data.secondaryImg}
                      className="img-fluid"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="max-w-[408.55px] mt-[150px]">
                    <img
                      src="/go-logo-reverse@2x.png"
                      alt=""
                      width="408.55px"
                    />
                  </div>
                </div>
              </>
            ) : webinarOverlayType === "overlay4" ? (
              <>
                <div id="left" className="relative">
                  <div className="max-w-[270.64px] mt-[40.14px] ml-[48.56px]">
                    <img
                      src="/go-logo-reverse@2x.png"
                      alt=""
                      width="408.55px"
                    />
                  </div>
                  <div className="max-w-[436.25px] h-[272.01px] ml-[48.56px] mt-[0px] flex items-end">
                    <h2 className={`text-white ${fontStyle}`}>
                      {topText
                        ? topText
                        : "Title of the webinar or seminar goes here"}
                    </h2>
                  </div>
                </div>
                <div id="right">
                  <div className="text-area flex items-top">
                    <div className="mt-[65.83px]">
                      <p
                        className={`text-[20px] leading-[24.2px] ${btmFontStyle}`}
                      >
                        {props.data.presenterName
                          ? props.data.presenterName
                          : " FIRSTNAME LASTNAME"}
                      </p>
                      <p
                        className={`text-[15px] leading-[18.15px] ${btmFontStyle}`}
                      >
                        {props.data.presenterCreds
                          ? props.data.presenterCreds
                          : " TITLE | POSITION | CREDITIALS"}
                      </p>
                    </div>
                  </div>
                  <div className="img-area">
                    <img
                      src={props.data.secondaryImg}
                      className="img-fluid"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div>
                <div
                  id="bottom"
                  className="flex relative justify-start items-center"
                >
                  <p className={btmFontStyle}>
                    {date ? (
                      <>
                        {date}, {time}
                      </>
                    ) : (
                      "Month DD, HH:MM TIMEZONE"
                    )}
                  </p>
                </div>
              </>
            ) : (
              <div
                className={`grid  ${
                  webinarOverlayType === "overlay2"
                    ? "grid-cols-[259px_auto_auto]"
                    : "grid-cols-3"
                } gap-0`}
              >
                <div
                  className={`grid-rows-3 col-span-1 ${
                    webinarOverlayType === "overlay1" ? "order-2" : "order-1"
                  }`}
                >
                  {webinarOverlayType === "overlay2" ? (
                    <div>
                      <>
                        {checked ? (
                          <img
                            src={props.data.secondaryImg}
                            className="img-fluid"
                            alt=""
                            width="100%"
                          />
                        ) : (
                          <img
                            src="/webinar-hero.jpg"
                            className="img-fluid"
                            alt=""
                            width="100%"
                          />
                        )}
                      </>
                    </div>
                  ) : null}

                  {checked ? (
                    <>
                      <div
                        className={`flex flex-col items-start py-4 px-6 bg-bg ${
                          webinarOverlayType === "overlay2"
                            ? "min-h-[182px]"
                            : "min-h-[147px]"
                        }`}
                      >
                        <>
                          {presenterName ? (
                            <p
                              className={`font-body text-sub text-sm font-bold leading-4 mt-auto mb-3 ${btmFontStyle}`}
                            >
                              {props.data.presenterName}
                            </p>
                          ) : (
                            <p
                              className={`font-body text-sub text-sm font-bold leading-4 ${
                                webinarOverlayType === "overlay2"
                                  ? "mt-7"
                                  : "mt-auto"
                              } mb-3 ${btmFontStyle}`}
                            >
                              FIRSTNAME <br /> LASTNAME
                            </p>
                          )}
                          {presenterCreds ? (
                            <p
                              className={`font-body text-sub text-xs font-bold leading-4 ${btmFontStyle}`}
                            >
                              {props.data.presenterCreds}
                            </p>
                          ) : (
                            <p
                              className={`font-body text-sub text-xs font-bold leading-4 ${btmFontStyle}`}
                            >
                              TITLE | POSITION | CREDENTIALS
                            </p>
                          )}
                        </>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`${props.data.subTextColor} flex ${
                          webinarOverlayType === "overlay1"
                            ? "items-end min-h-[147px]"
                            : "items-center min-h-[182px]"
                        } py-4 px-6 bg-black`}
                      >
                        <>
                          {subText ? (
                            <h5
                              className={`font-body text-dark leading-5 ${btmFontStyle} ${
                                webinarOverlayType === "overlay1"
                                  ? "text-[15px]"
                                  : "text-lg"
                              } font-bold`}
                            >
                              {props.data.subText}
                            </h5>
                          ) : (
                            <h5
                              className={`font-body text-dark leading-5 ${btmFontStyle} ${
                                webinarOverlayType === "overlay1"
                                  ? "text-[15px]"
                                  : "text-lg"
                              } font-bold`}
                            >
                              FREE WEBINAR OR SERIES TITLE
                            </h5>
                          )}
                        </>
                      </div>
                    </>
                  )}
                  {webinarOverlayType === "overlay2" ? (
                    <>
                      <div>
                        {checked ? (
                          <img
                            src={props.data.webinarImg}
                            className="img-fluid"
                            alt=""
                            width="100%"
                          />
                        ) : (
                          <img
                            src={props.data.webinarImg}
                            className="img-fluid"
                            alt=""
                            width="100%"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {checked ? (
                        <img
                          src={props.data.secondaryImg}
                          className="img-fluid"
                          alt=""
                          width="100%"
                        />
                      ) : (
                        <img
                          src={props.data.webinarImg}
                          className="img-fluid"
                          alt=""
                          width="100%"
                        />
                      )}
                    </>
                  )}
                </div>
                <div
                  className={`col-span-2 grid grid-rows-3 ${
                    webinarOverlayType === "overlay1" ? "order-1" : "order-2"
                  }`}
                >
                  <div
                    className={`bg-dark  row-[span_16_/_span_16] ${
                      webinarOverlayType === "overlay1" ? "p-5" : "p-8"
                    }`}
                  >
                    {webinarOverlayType === "overlay2" ? (
                      <img src="/go-logo-reverse@2x.png" alt="" width="270" />
                    ) : (
                      <img src="/go-logo-reverse@2x.png" alt="" width="250" />
                    )}
                    <div
                      className={`${
                        webinarOverlayType === "overlay1"
                          ? "h-[15.5rem]"
                          : "h-[32rem]"
                      } flex items-end`}
                    >
                      {topText ? (
                        <h2
                          className={`topText text-white ${fontStyle} ${props.data.topTextSize}`}
                        >
                          {props.data.topText}
                        </h2>
                      ) : (
                        <h2
                          className={`topText text-white font-semibold ${fontStyle} ${props.data.topTextSize}`}
                        >
                          Webinar theme or educational topic
                        </h2>
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex items-center ${
                      webinarOverlayType === "overlay1"
                        ? "h-[84px] text-left"
                        : "h-[80px] text-center"
                    } bg-primary p-5 `}
                  >
                    {date ? (
                      <p
                        className={`w-full font-body ${btmFontStyle} ${
                          webinarOverlayType === "overlay1"
                            ? "text-lg"
                            : "text-xl"
                        } font-normal text-white bottomText`}
                      >
                        <span>
                          <>{date + ", " + time}</>
                        </span>
                      </p>
                    ) : (
                      <p
                        className={`w-full font-body ${btmFontStyle} ${
                          webinarOverlayType === "overlay1"
                            ? "text-lg"
                            : "text-xl"
                        } font-normal text-white bottomText`}
                      >
                        Month DD, HH:MM TIMEZONE
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Generated component */}
          <div className="sr-only">
            <div
              id="gen-img"
              className={`DisplayImg ${props.data.webinaroverlay} lg:block`}
            >
              {webinarOverlayType === "overlay3" ? (
                <>
                  <div
                    id="top"
                    className="flex relative justify-center items-end"
                  >
                    <div className="max-w-[830px] max-h-[440px] mb-[450px]">
                      <h2 className={`text-white ${fontStyle}`}>
                        {topText
                          ? topText
                          : "Title of the webinar or seminar goes here"}
                      </h2>
                    </div>
                    <div className="text-area flex items-center">
                      <div>
                        <p
                          className={`text-[36px] leading-[43.57px] ${btmFontStyle}`}
                        >
                          {props.data.presenterName
                            ? props.data.presenterName
                            : " FIRSTNAME LASTNAME"}
                        </p>
                        <p
                          className={`text-[28px] leading-[33.89px] ${btmFontStyle}`}
                        >
                          {props.data.presenterCreds
                            ? props.data.presenterCreds
                            : " TITLE | POSITION | CREDITIALS"}
                        </p>
                      </div>
                    </div>
                    <div className="img-area">
                      <img
                        src={props.data.webinarImg}
                        className="img-fluid"
                        alt=""
                        width="100%"
                      />
                    </div>
                  </div>
                  <div
                    id="bottom"
                    className="flex relative justify-center items-center"
                  >
                    <div className="text-area flex justify-start items-center">
                      <p className={btmFontStyle}>
                        {date ? (
                          <>{date + ", " + time}</>
                        ) : (
                          "Month DD, HH:MM TIMEZONE"
                        )}
                      </p>
                    </div>
                    <div className="img-area">
                      <img
                        src={props.data.secondaryImg}
                        className="img-fluid"
                        alt=""
                        width="100%"
                      />
                    </div>
                    <div className="max-w-[631px] mt-[152px]">
                      <img src="/go-logo-reverse@2x.png" alt="" width="100%" />
                    </div>
                  </div>
                </>
              ) : webinarOverlayType === "overlay4" ? (
                <>
                  <div id="left" className="relative">
                    <div className="max-w-[418.55px] mt-[71.2px] ml-[75px]">
                      <img src="/go-logo-reverse@2x.png" alt="" width="100%" />
                    </div>
                    <div className="max-w-[751px] h-[451px] ml-[75px] mt-[144.12px] flex items-end">
                      <h2 className={`text-white ${fontStyle}`}>
                        {topText
                          ? topText
                          : "Title of the webinar or seminar goes here"}
                      </h2>
                    </div>
                  </div>
                  <div id="right">
                    <div className="text-area flex items-top">
                      <div className="mt-[148px]">
                        <p
                          className={`text-[32px] leading-[38.73px] ${btmFontStyle}`}
                        >
                          {props.data.presenterName
                            ? props.data.presenterName
                            : " FIRSTNAME LASTNAME"}
                        </p>
                        <p
                          className={`text-[24px] leading-[29.05px] ${btmFontStyle}`}
                        >
                          {props.data.presenterCreds
                            ? props.data.presenterCreds
                            : " TITLE | POSITION | CREDITIALS"}
                        </p>
                      </div>
                    </div>
                    <div className="img-area">
                      <img
                        src={props.data.secondaryImg}
                        className="img-fluid"
                        alt=""
                        width="100%"
                      />
                    </div>
                  </div>
                  <div
                    id="bottom"
                    className="flex relative justify-start items-center"
                  >
                    <p className={btmFontStyle}>
                      {date ? (
                        <>{date + ", " + time}</>
                      ) : (
                        "Month DD, HH:MM TIMEZONE"
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <div
                  className={`grid  ${
                    webinarOverlayType === "overlay1"
                      ? "grid-cols-[auto_auto_413px]"
                      : "grid-cols-[433px_auto_auto]"
                  } gap-0`}
                >
                  <div
                    className={`grid-rows-3 col-span-1 ${
                      webinarOverlayType === "overlay1" ? "order-2" : "order-1"
                    }`}
                  >
                    {webinarOverlayType === "overlay2" ? (
                      <div>
                        <>
                          {checked ? (
                            <img
                              src={props.data.secondaryImg}
                              className="img-fluid"
                              alt=""
                              width="100%"
                            />
                          ) : (
                            <img
                              src="/webinar-hero.jpg"
                              className="img-fluid"
                              alt=""
                              width="100%"
                            />
                          )}
                        </>
                      </div>
                    ) : null}
                    {checked ? (
                      <>
                        <div
                          className={`flex flex-col items-start py-4 px-11 bg-bg min-h-[215px]`}
                        >
                          <>
                            {presenterName ? (
                              <p
                                className={`font-body text-sub text-[32px] font-bold leading-[38px] mt-auto mb-3 ${btmFontStyle}`}
                              >
                                {props.data.presenterName}
                              </p>
                            ) : (
                              <p
                                className={`font-body text-sub text-[32px] font-bold leading-[38px] mt-auto mb-3 ${btmFontStyle}`}
                              >
                                FIRSTNAME <br /> LASTNAME
                              </p>
                            )}
                            {presenterCreds ? (
                              <p
                                className={`font-boy text-sub text-[24px] font-bold leading-[32px] mb-4 ${btmFontStyle}`}
                              >
                                {props.data.presenterCreds}
                              </p>
                            ) : (
                              <p
                                className={`font-body text-sub text-[24px] font-bold leading-[32px] mb-4 ${fontStyle}`}
                              >
                                TITLE | POSITION | CREDENTIALS
                              </p>
                            )}
                          </>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={`${props.data.subTextColor} flex ${
                            webinarOverlayType === "overlay1"
                              ? "items-end  min-h-[215px]"
                              : "items-center  min-h-[240px]"
                          } py-10 px-14 bg-black`}
                        >
                          <>
                            {subText ? (
                              <h5
                                className={`font-body text-dark ${btmFontStyle} ${
                                  webinarOverlayType === "overlay1"
                                    ? "text-3xl"
                                    : "text-3xl"
                                } font-bold`}
                              >
                                {props.data.subText}
                              </h5>
                            ) : (
                              <h5
                                className={`font-body text-dark ${btmFontStyle} ${
                                  webinarOverlayType === "overlay1"
                                    ? "text-3xl"
                                    : "text-3xl"
                                } font-bold`}
                              >
                                FREE WEBINAR OR SERIES TITLE
                              </h5>
                            )}
                          </>
                        </div>
                      </>
                    )}
                    {webinarOverlayType === "overlay2" ? (
                      <>
                        <div>
                          {checked ? (
                            <img
                              src={props.data.webinarImg}
                              className="img-fluid"
                              alt=""
                              width="100%"
                            />
                          ) : (
                            <img
                              src={props.data.webinarImg}
                              className="img-fluid"
                              alt=""
                              width="100%"
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {checked ? (
                          <img
                            src={props.data.secondaryImg}
                            className="img-fluid"
                            alt=""
                            width="100%"
                          />
                        ) : (
                          <img
                            src={props.data.webinarImg}
                            className="img-fluid"
                            alt=""
                            width="100%"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={`col-span-2 grid grid-rows-3 ${
                      webinarOverlayType === "overlay1" ? "order-1" : "order-2"
                    }`}
                  >
                    <div
                      className={`bg-dark ${
                        webinarOverlayType === "overlay1"
                          ? "px-10 py-8 h-[493px]"
                          : "px-14 py-12 h-[920px]"
                      }`}
                    >
                      <img src="/go-logo-reverse@2x.png" alt="" width="335" />
                      <div
                        className={`${
                          webinarOverlayType === "overlay1"
                            ? "h-[23rem]"
                            : "h-[47rem]"
                        } flex items-end`}
                      >
                        {topText ? (
                          <h2
                            className={`topText text-white font-semibold ${fontStyle} ${props.data.topTextSize}`}
                          >
                            {props.data.topText}
                          </h2>
                        ) : (
                          <h2
                            className={`topText text-white font-semibold ${fontStyle} ${props.data.topTextSize}`}
                          >
                            Webinar theme or educational topic
                          </h2>
                        )}
                      </div>
                    </div>
                    <div
                      className={`flex items-center ${
                        webinarOverlayType === "overlay1"
                          ? "h-[135px] text-left px-14"
                          : "h-[160px] text-left px-14"
                      } bg-primary `}
                    >
                      {date ? (
                        <p
                          className={`w-full font-body text-[36px] font-semibold text-white bottomText ${btmFontStyle}`}
                        >
                          <span>{date + ", " + time}</span>
                        </p>
                      ) : (
                        <p
                          className={`w-full font-body text-[36px] leading-[134] font-semibold text-white bottomText !bg-transparent ${btmFontStyle}`}
                        >
                          Month DD, HH:MM TIMEZONE
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* End of generated component */}
          <div className="button-wrapper">
            <button
              type="button"
              className="btn mt-11 rounded st-4"
              onClick={(e) => {
                setSelected(initialState);
                props.handleFormReset(e);
              }}
            >
              Reset Preview
            </button>
            <button
              className="btn btn-primary ease-linear transition-all duration-150 rounded st-5"
              type="button"
              onClick={props.handleJpeg}
            >
              Generate Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Webinars;
