import { Tabs } from "flowbite-react";
import { useRef, useState } from "react";
import { Console } from "../../layouts/console";
import useScreenObserver from "../../hooks/useScreenObserver";
import { GridItems, TabItems } from "../../utilities/objects";
import { Cards } from "../../layouts/cards";
import { Section } from "../../layouts/section";
import Options from "./options";

let style = {
  tabStyle: "pills",
};

export const ContentPanel = () => {
  const [show, setShow] = useState(false);
  const [moreBtn, setMoreBtn] = useState("Show more...");

  const ref = useRef();
  const screenObserve = useScreenObserver(ref);

  function handleGrid() {
    if (show) {
      setShow(false);
      setMoreBtn("Show more...");
    } else {
      setShow(true);
      setMoreBtn("Show less...");
    }
  }

  return (
    <div className="container">
      <div className="mt-32 lg:px-6">
        <h2 className="block text-main font-display text-center mb-4 text-3xl font-bold tracking-tight sm:text-5xl">
          What will you create next?
        </h2>
        <div className="tabs max-w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 sm:flex sm:items-center sm:justify-center">
          <Tabs.Group
            aria-label="Pills"
            style={style.tabStyle}
            className="d-flex justify-center"
          >
            {TabItems().map((item, index) => {
              return (
                <Tabs.Item
                  key={index}
                  active={index === 0 ? true : false}
                  title={item.title}
                >
                  <div className="grid grid-cols-1  gap-x-10 gap-y-18 sm:grid-cols-2 lg:grid-cols-3">
                    {item.content
                      .map((card, i) => {
                        return <Cards key={i} card={card} />;
                      })
                      .reverse()}
                  </div>
                </Tabs.Item>
              );
            })}
          </Tabs.Group>
        </div>
      </div>

      <Section>
        <div
          ref={ref}
          className="hero_wrapper box_fade order-last lg:order-none hidden sm:block"
        >
          <div className="text-left mt-3 text-base  sm:text-lg sm:max-w-xl lg:max-w-lg sm:mx-auto  sm:mt-40 md:mb-40 md:text-xl">
            <div className="editor_handlebar">
              <span className="editor_handlebar_buttons"></span>
            </div>
            <div className="editor_content">
              {screenObserve && (
                <Console text={"Stay updated on everything we ship..."} />
              )}
            </div>
          </div>
        </div>
        <div className="justify-center text-center lg:text-start lg:justify-start sm:px-0 bg-dark sm:bg-white opacity-90 sm:opacity-1 rounded-xl sm:rounded-none text-white sm:text-black p-10 sm:p-0">
          <h2 className="block font-display mb-4 text-3xl font-bold tracking-tight sm:text-5xl text-pink-400 sm:text-main">
            See what we are doing
          </h2>

          <p className="block font-display font-normal text-[1.375rem] leading-8">
            Stay up to date with the latest features and improvements across our
            websites.
          </p>
          {/*  <div className="flex mt-8 link-variant justify-center text-center lg:text-start lg:justify-start">
            <a
              href="https://changelog.gom.digital"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-medium inline-flex items-center"
            >
              View Sites Changelog
              <ArrowRight />
            </a>
          </div> */}
        </div>
      </Section>

      <Section>
        <div className="justify-center text-center lg:text-start lg:justify-start px-1 sm:px-0 ">
          <h2 className="block font-display mb-4 text-3xl font-bold tracking-tight text-main sm:text-5xl">
            Share your ideas with us
          </h2>
          <p className="block font-display font-normal text-[1.375rem] leading-8 text-main">
            Use our teams request forms to brief in your next project
          </p>
        </div>

        <div className="octagon">
          <Options />
        </div>
      </Section>

      <div className="justify-center max-w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-14 md:mt-40">
        <h2 className=" block text-center font-display mb-4 text-3xl font-bold tracking-tight text-main sm:text-5xl">
          Before you go, there's more
        </h2>
        <p className="text-center font-display font-normal text-[1.375rem] leading-8 text-main mb-20">
          Access more GO Markets resources &#38; learn about the digital
          landscape
        </p>

        <div
          className={`grid grid-cols-1 gap-2 lg:gap-4 sm:grid-cols-2 lg:grid-cols-4 ${
            !show ? "max-h-[30rem] overflow-hidden" : ""
          } `}
        >
          {GridItems().map((c, index) => {
            return (
              <ul className="space-y-4" key={index}>
                {c.items &&
                  c.items.map((item, i) => {
                    return (
                      <li className="text-sm leading-6" key={i}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex justify-center"
                        >
                          <div
                            style={{
                              backgroundImage: `url(${item.image})`,
                            }}
                            className={`relative flex bg-cover hover:scale-105 bg-center bg-no-repeat flex-col-reverse bg-slate-50 rounded-md transition-all ease-in-out delay-50   dark:bg-slate-800 dark:highlight-white/5 border hover:border-primary ${
                              item.priority === "high"
                                ? "w-80 h-40 md:h-72 sm:w-full"
                                : item.priority === "med"
                                ? "w-80 h-40 md:h-56 sm:w-full"
                                : item.priority === "low" ||
                                  item.priority === ""
                                ? "w-80 h-40 md:h-40 sm:w-full"
                                : ""
                            }`}
                          >
                            <div className="p-4 gallary_content shadow-xl ">
                              <p className="text-lg font-bold text-white">
                                {item.title}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            );
          })}
        </div>
        <div
          className={`inset-x-0 -mt-48 flex bg-gradient-to-t from-white pt-44 pb-4 pointer-events-none dark:from-slate-900  transition-opacity  ${
            !show ? "absolute " : "sticky -mt-52  duration-300 opacity-0"
          }`}
        ></div>
        <div className="flex justify-center">
          <button
            onClick={handleGrid}
            className="text-sm font-medium show-more mt-10 relative focus:outline-none rounded-lg flex items-center pointer-events-auto"
          >
            {moreBtn}
          </button>
        </div>
      </div>
    </div>
  );
};
