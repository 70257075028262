import React from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Walkthrough from "../../walkthrough/walkthrough";
import {
  Back,
  Last,
  Next,
  Skip,
  Title,
  ValidateRun,
} from "../../walkthrough/settings";

import { LanguagePanel } from "../../../layouts/imgGenerator/language-panel";
import {
  DetectLanguage,
  TranslateInput,
} from "../../../services/firebaseActions/language";
import { LanguageFont } from "../../../utilities/utilityService";
import {
  images,
  imageSizes,
} from "../../../helpers/imgGenerator/newsAnalysisObj";
import { ImageSizeSelector } from "../../../layouts/imageSizeSelector";

const initialState = {
  id: 1,
  name: "Default",
  image: "/go-default.jpeg",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ImgDisplay(props) {
  const [selected, setSelected] = useState(images[0]);
  const overlayType = props.data.overlay;
  const topText = props.data.topText;
  const bottomText = props.data.bottomText;

  //translator
  const translateTop = TranslateInput(topText, "en", props.data.lang);
  const translateBottom = TranslateInput(bottomText, "en", props.data.lang);
  //detector
  const detectLang = DetectLanguage(topText);
  //font
  const { fontStyle, btmFontStyle } = LanguageFont(detectLang);

  return (
    <div>
      <Walkthrough
        run={ValidateRun("imgGenTutorial")}
        tutorialStatus={"imgGenTutorial"}
        walkthSteps={[
          {
            disableBeacon: true,
            target: ".st-1",
            title: <Title>Template Type</Title>,
            content:
              "Select what template would like to use. We have templates that have different styles, eidting options to suit your purpose.",
            offset: -10,
            locale: {
              next: <Next />,
              skip: <Skip />,
            },
          },
          {
            disableBeacon: true,
            title: <Title>Asset Settings</Title>,
            target: ".st-2",
            offset: -10,
            content:
              "Use any number of optional fields or items to customise your asset.",
            locale: {
              next: <Next />,
              skip: <Skip />,
            },
          },
          {
            disableBeacon: true,
            target: ".st-3",
            offset: -50,
            title: <Title>Asset Preview</Title>,
            content: "See how your tailored asset will look as you build it",
            locale: {
              next: <Next />,
              skip: <Skip />,
            },
          },

          {
            disableBeacon: true,
            target: ".st-4",
            title: <Title>Ready to go?</Title>,
            content:
              "When your are happy with how it looks, download your tailored asset or reset the fields to start again.",
            locale: {
              back: <Back />,
              last: <Last />,
            },
          },
        ]}
      >
        <div className="grid grid-cols-12 gap-8 sm:px-4">
          <div className="col-span-12 sm:col-span-12 lg:col-span-5">
            <p className="mb-6">
              Create and customise your image by using the fields below.
            </p>

            <form>
              <div className="st-2">
                <h3 className="font-display text-lg font-bold text-sub mb-7">
                  Image Content
                </h3>
                <div className="image-details">
                  <div className="input-group">
                    <label className="font-body text-sm font-normal text-sub w-full">
                      Title
                    </label>
                    <input
                      className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                      type="text"
                      name="topText"
                      placeholder="Enter your title here"
                      value={props.data.topText}
                      onChange={props.handleChange}
                    />
                    <select
                      defaultValue={"large"}
                      onChange={props.handleChange}
                      className="w-2/5 border-border mb-4 cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen"
                      id="demo-simple-select"
                      label="Set Font Size"
                      name="topTextSize"
                    >
                      <option disabled={true} value="DEFAULT">
                        Set Font Size
                      </option>
                      <option value="extra-small">46px | Extra Small</option>
                      <option value="small">54px | Small</option>
                      <option value="medium">66px | Medium</option>
                      <option value="large">74px | Large</option>
                      <option value="oversized">88px | Oversized</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label className="font-body text-sm font-normal text-sub w-full">
                      Author
                    </label>
                    <input
                      className="form-control text-sm w-full border-border mt-1.5 mb-4 focus:border-gogreen focus:ring-gogreen"
                      type="text"
                      name="bottomText"
                      placeholder="Enter authors name and or title"
                      value={props.data.bottomText}
                      onChange={props.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="image-details">
                <h3 className="font-display text-lg font-bold text-sub mb-7">
                  Image Settings
                </h3>

                <div className="image-settings">
                  <div className="flex justify-left">
                    <ImageSizeSelector
                      handleChange={props.handleChange}
                      sizeObj={imageSizes}
                      name="overlay"
                    />
                  </div>
                  <div className="input-group my-8">
                    <div className="form-group">
                      <Listbox
                        value={selected}
                        onChange={(e) => {
                          setSelected(e);
                          props.handleSelect(e);
                        }}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="font-body text-sm font-normal text-sub text-muted mb-3">
                              Select Key Visual
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full cursor-pointer border border-border bg-white py-2 pl-3 pr-10 text-left focus:border-gogreen focus:outline-none focus:ring-1 focus:ring-gogreen sm:text-sm">
                                <span className="flex items-center cursor-pointer">
                                  <img
                                    src={selected.image}
                                    alt=""
                                    className="h-6 w-6 flex-shrink-0"
                                  />
                                  <span className="ml-3 block truncate text-sm">
                                    {selected.name}
                                  </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="grid grid-cols-4 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {[...images].reverse().map((imageitem) => (
                                    <Listbox.Option
                                      key={imageitem.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? "text-white" : "text-sub",
                                          "relative cursor-pointer select-none py-2 pl-3 pr-9 w-full"
                                        )
                                      }
                                      value={imageitem}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="block">
                                            <img
                                              src={imageitem.image}
                                              alt=""
                                              className="h-20 w-auto flex-shrink-0"
                                            />
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "text-sub text-xs mt-2 block truncate"
                                              )}
                                            >
                                              {imageitem.name}
                                            </span>
                                          </div>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-gogreen"
                                                  : "text-gogreen",
                                                "absolute inset-y-0 top-3 left-4 flex items-top pr-4 z-50"
                                              )}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                              </svg>
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                  <p className="font-body text-sm font-normal text-sub text-muted mb-3">
                    Or Upload Key Visual{" "}
                    <span className="italic text-xs">
                      (780px x 780px for best results.)
                    </span>
                  </p>
                  <div className="input-group">
                    <div className="custom-file relative">
                      <div className="max-w-xl">
                        <label
                          htmlFor="dropzone-file"
                          className="flex flex-col justify-center items-center w-full h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                          <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="mb-3 w-10 h-10 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                              or drag and drop
                            </p>

                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              SVG, PNG, JPG or GIF (MAX. 400x400px)
                            </p>
                          </div>
                          <input
                            onChange={props.handleChange}
                            type="file"
                            className="custom-file-input block w-full font-body text-sm text-sub cursor-pointer absolute h-full color-transparent -indent-56 focus:border-gogreen focus:ring-gogreen"
                            id="inputGroupFile04"
                            aria-describedby="inputGroupFileAddon04"
                            accept="image/x-png,image/gif,image/jpeg"
                            name="randomImg"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LanguagePanel
                topText={topText}
                translateTop={translateTop}
                bottomText={bottomText}
                translateBottom={translateBottom}
                handleLanguage={props.handleChange}
                topTextPlaceHolder="Enter your title here"
                btmTextPlaceHolder="Enter authors name or title"
              />
            </form>
          </div>
          <div className="col-span-7 sm:pl-0 lg:pl-11">
            <div
              id="gen-img-preview"
              className={`DisplayImg DisplayImgPreview ${props.data.overlay} hidden sm:hidden lg:block st-3`}
            >
              {overlayType === "overlay1" && (
                <img
                  src="/go-logo-reverse@2x.png"
                  alt=""
                  className="gomarkets-logo"
                  width="180"
                />
              )}
              {overlayType === "overlay2" && (
                <img
                  src="/go-logo-rev-port@2x.png"
                  alt=""
                  className="gomarkets-logo"
                  width="240"
                />
              )}
              {overlayType === "overlay1" && (
                <img
                  src="/Octagon-mask@3x2.png"
                  alt=""
                  className="gomarkets-overlay"
                  width="100%"
                />
              )}
              {overlayType === "overlay2" && (
                <img
                  src="/octagon-mask-2@2x.png"
                  alt=""
                  className="gomarkets-overlay"
                  width="100%"
                />
              )}
              <img
                src={props.data.randomImg}
                className="img-fluid img-thumbnail"
                alt=""
                width="100%"
              />
              {topText ? (
                <h2
                  className={`topText ${fontStyle} ${props.data.topTextSize}`}
                >
                  {props.data.topText}
                </h2>
              ) : (
                <h2 className={`topText ${props.data.topTextSize}`}>
                  Enter your title here
                </h2>
              )}
              {bottomText ? (
                <p
                  className={`font-body text-base font-normal text-sub bottomText ${btmFontStyle}`}
                >
                  {props.data.bottomText}
                </p>
              ) : (
                <p
                  className={`font-body text-base font-normal text-sub bottomText ${btmFontStyle}`}
                >
                  Enter authors name and or title
                </p>
              )}
            </div>

            {/* Generated component */}
            <div className="sr-only">
              <div
                id="gen-img"
                className={`DisplayImg ${props.data.overlay} lg:block`}
              >
                {overlayType === "overlay1" && (
                  <img
                    src="/go-logo-reverse@2x.png"
                    alt=""
                    id="template1-logo"
                    className="gomarkets-logo .template1-logo"
                    width="365"
                  />
                )}
                {overlayType === "overlay2" && (
                  <img
                    src="/go-logo-rev-port@2x.png"
                    alt=""
                    id="template1-logo"
                    className="gomarkets-logo"
                    width="240"
                  />
                )}
                {overlayType === "overlay1" && (
                  <img
                    src="/Octagon-mask@3x2.png"
                    alt=""
                    id="template1-logo"
                    className="gomarkets-overlay"
                    width="100%"
                  />
                )}
                {overlayType === "overlay2" && (
                  <img
                    src="/octagon-mask-2@2x.png"
                    alt=""
                    id="template1-logo"
                    className="gomarkets-overlay"
                    width="100%"
                  />
                )}
                <img
                  src={props.data.randomImg}
                  className="img-fluid img-thumbnail"
                  alt=""
                  width="780"
                  height="780"
                />
                <h2
                  className={`topText ${fontStyle} ${props.data.topTextSize}`}
                >
                  {props.data.topText}
                </h2>
                <p
                  className={`font-body text-base font-normal text-sub bottomText ${btmFontStyle}`}
                >
                  {props.data.bottomText}
                </p>
              </div>
            </div>
            {/* End of generated component */}
            <div className="button-wrapper">
              <button
                type="button"
                className="btn mt-11 rounded st-4"
                onClick={(e) => {
                  setSelected(initialState);
                  props.handleFormReset(e);
                }}
              >
                Reset Preview
              </button>
              <button
                className="btn btn-primary ease-linear transition-all duration-150 rounded"
                type="button"
                onClick={props.handleJpeg}
              >
                Generate Image
              </button>
            </div>
          </div>
        </div>
      </Walkthrough>
    </div>
  );
}

export default ImgDisplay;
