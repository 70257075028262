import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CategoryObj } from "./objects";

const FormType = () => {
  const [formType, setFormType] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      CategoryObj().some(
        (e) => e.value === location.state.value && setFormType(e.value)
      );
    } else {
      CategoryObj().some(
        (e) => location.pathname.includes(e.routes) && setFormType(e.value)
      );
    }
  }, [location.state, location]);

  return formType;
};

export default FormType;
