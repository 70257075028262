import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Hero } from "../layouts/hero";
import { UPLOAD } from "../routes/routes";
import { MainLayout } from "../layouts/mainLayout";
import { Dashboard } from "../components/file-uploader/dashboard";
import { CategoryObj } from "../helpers/fileUploader/objects";

function FileUploader() {
  const location = useLocation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (location.pathname === `/${UPLOAD}`) {
      setTitle("File Uploader");
    } else {
      if (location.state) {
        CategoryObj().some((e) =>
          e.value === location.state.value ? setTitle(e.title) : ""
        );
      } else {
        CategoryObj().some((e) =>
          location.pathname.includes(e.routes) ? setTitle(e.title) : ""
        );
      }
    }
  }, [location.state, location.pathname]);

  return (
    <MainLayout>
      <div className="main">
        <Hero title={title} logo={false} />
        <Dashboard />
      </div>
    </MainLayout>
  );
}

export default FileUploader;
