import { Badge, Card, Spinner } from "flowbite-react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ArrowRight } from "../../layouts/icons";
import { UPLOAD } from "../../routes/routes";
import { useContext } from "react";
import { UploadContext } from "../../context/UploadProvider";
import {
  C_DIVIDEND_FEED,
  C_LEGAL_DOCUMENTS,
  C_TRADING_HOURS,
} from "../../constants/types";
import { CategoryObj } from "../../helpers/fileUploader/objects";

export const Dashboard = () => {
  const location = useLocation();
  const { data, isLoading } = useContext(UploadContext);

  //get total file count of each category
  const uploadCount = (value) => {
    if (data[0]) {
      if (value === C_LEGAL_DOCUMENTS) {
        const legalDocsObject = data[0][value];
        let totalCount = 0;

        // Iterate through keys in the legal documents object and calculate total count
        for (const key in legalDocsObject) {
          if (legalDocsObject.hasOwnProperty(key)) {
            totalCount += Object.keys(legalDocsObject[key]).length;
          }
        }

        return totalCount;
      } else if (value === C_DIVIDEND_FEED || value === C_TRADING_HOURS) {
        return 1;
      } else {
        return Object.keys(data[0][value]).length;
      }
    }
  };

  return (
    <div className="container">
      <div className="max-w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-16 sm:flex sm:items-top sm:justify-center">
        {location.pathname === `/${UPLOAD}` ||
        location.pathname === `/${UPLOAD}/` ? (
          <div className="grid gap-2 sm:gap-6 lg:grid-cols-3 sm:grid-cols-2 w-full">
            {CategoryObj().map((item, index) => {
              return (
                <Card key={index}>
                  <div className="flex justify-between">
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {item.title}
                    </h5>
                  </div>
                  {!isLoading ? (
                    <>
                      <div className="flex justify-between">
                        <p className="font-normal text-gray-700">
                          Last updated
                        </p>
                        {data[0] && data[0][item.value].createdAt && (
                          <Badge>
                            {new Date(
                              data[0][item.value].createdAt
                            ).toLocaleDateString()}
                          </Badge>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <p className="font-normal text-gray-700">
                          Total uploads
                        </p>
                        <Badge> {uploadCount(item.value)} Uploaded</Badge>
                      </div>
                    </>
                  ) : (
                    <Spinner />
                  )}

                  <Link
                    to={`/${UPLOAD}/${item.routes}`}
                    state={{ title: item.title, value: item.value }}
                    className="inline-flex items-center text-center text-primary w-max"
                  >
                    Upload
                    <ArrowRight />
                  </Link>
                </Card>
              );
            })}
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};
