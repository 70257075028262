import {
  DIVIDEND_FEED,
  GENERAL,
  LEGAL_DOCUMENTS,
  TRADING_HOURS,
} from "../../routes/routes";

//File uploader
export const LegalCategories = () => {
  const categories = [
    {
      category: "Asic",
      value: "asic",
    },
    {
      category: "Cysec",
      value: "cysec",
    },
    {
      category: "Go61",
      value: "go61",
    },
    {
      category: "Group",
      value: "group",
    },
    {
      category: "Mena",
      value: "mena",
    },
    {
      category: "MU",
      value: "mu",
    },
    {
      category: "Multi-jurisdiction",
      value: "multi_jurisdiction",
    },
    {
      category: "SC",
      value: "sc",
    },
    {
      category: "SVG",
      value: "svg",
    },
  ];

  return categories;
};

export const CategoryObj = () => {
  const formOptions = [
    {
      title: "General",
      routes: GENERAL,
      value: "general",
      content:
        "All-purpose file uploader for securely storing and accessing non-sensitive documents.",
    },
    {
      title: "Dividend Feed",
      routes: DIVIDEND_FEED,
      value: "dividend_feed",
      content: "Efficiently manage and update Dividend Feed versions here.",
    },
    {
      title: "Trading Hours",
      routes: TRADING_HOURS,
      value: "trading_hours",
      content: "Maintain and update trading hours data for Go Markets here.",
    },
    {
      title: "Legal Documents",
      routes: LEGAL_DOCUMENTS,
      value: "legal_documents",
      content: "Manage and update Go Markets essential legal documents here.",
    },
    //testing - comment before merging to main
    /* {
      title: "Test",
      routes: "test",
      value: "test",
      content:
        "This is a test uploader. Be sure to comment it out before merging to main",
    }, */
  ];

  return formOptions;
};

export const FilterOptions = () => {
  return [
    { value: "all", label: "All (default)" },
    { value: "userUploaded", label: "Uploaded by you" },
  ];
};
